import React, {CSSProperties, ReactElement, RefObject, useRef, useState} from "react";
import cl from "./ImageLoader.module.css";
import error_img from "../images/error_loading.png";
import {useObserving} from "../../hooks/useObserving";

interface ImageLoaderProps {
    url: string,
    altUrl?: string,
    imgStyle: CSSProperties,
    animStyle: CSSProperties,
    alt: string,
    ImgAddon?: ReactElement,

    forceShowAnimation?: boolean,
    isVideo?: boolean,
    videoRef?: RefObject<HTMLVideoElement>,
    autoplay?: boolean,
}

export default function ImageLoader({url, altUrl, imgStyle, animStyle, alt, ImgAddon, forceShowAnimation, isVideo, videoRef, autoplay}: ImageLoaderProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    // load and view <img/> only if it on screen
    const observingElement = useRef<HTMLDivElement | null>(null);
    const [isReadyToLoad, setIsReadyToLoad] = useState(false);
    useObserving(observingElement, true, false, () => setIsReadyToLoad(true))

    function handleError() {
        setIsLoading(false);
        setImageError(true);
    }

    function getImgUrl() {
        if (imageError && altUrl) {
            return altUrl
        }
        if (imageError) {
            return error_img
        }
        return url
    }

    if (!url) {
        url = "";
    }

    // url = correctUrl(url);

    return (
        <>
            {(isLoading || forceShowAnimation) &&
                <>
                    <div className={cl.ImageLoadAnimation}
                         style={animStyle}>
                        <div ref={observingElement}
                             className={cl.ObservingElement}>
                        </div>
                    </div>
                </>
            }
            {isReadyToLoad && !isVideo && !forceShowAnimation &&
                <img key={url + altUrl}
                     onLoad={() => setIsLoading(false)}
                     onError={() => handleError()}
                     src={getImgUrl()} alt={alt}
                     style={{display: isLoading ? 'none' : 'block', ...imgStyle}}/>
            }
            {isVideo &&
                // video must be rendered always, otherwise intersection observer in parent component couldn't autoplay video
                <video key={url + altUrl}
                       ref={videoRef}
                       onCanPlay={() => setIsLoading(false)}
                       onError={() => handleError()}
                       controls={true}
                       muted={autoplay}
                       // src={url}
                       style={isLoading || forceShowAnimation ? {display:  'none'} : imgStyle}
                       controlsList="nodownload noplaybackrate"
                       disablePictureInPicture={true}
                       playsInline={true}>
                    <source src={url} type={"video/mp4"}/>
                </video>
            }
            {!isLoading && !imageError && ImgAddon}
        </>
    );
}

//temporarily for local dev
// export function correctUrl(url: string) {
//     if (appMode === "local" && url.includes(getConfig("dev").ServerURL) && !url.includes("/static/badges/")) {
//         return "http://localhost" + url.substring(26);
//     }
//     return url
// }